<template>
  <v-app id="inspire">
    <v-toolbar
      color="primary"
      dark
      fixed
      app
      clipped-right
    >
      <v-toolbar-side-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>Sistema de Candidatosss</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="logout"
      >
        <v-icon>
          exit_to_app
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      :width="220"
      fixed
      app
    >
      <slot name="drawer" />
    </v-navigation-drawer>
    <v-content>
      <keep-alive include="CandidatesList">
        <router-view />
      </keep-alive>
      <LoadingComponent />
      <GenericMessageDialog />
      <GenericSnackbar />
    </v-content>
    <v-footer
      color="primary"
      class="white--text"
      app
    >
      <v-spacer />
      <span>&copy; Mandomedio 2019</span>
    </v-footer>
  </v-app>
</template>

<script>
import GenericMessageDialog from '@/components/misc/GenericMessageDialog.vue';
import LoadingComponent from '@/components/misc/LoadingComponent.vue';
import GenericSnackbar from '@/components/misc/GenericSnackbar.vue';

export default {

  components: {
    GenericMessageDialog,
    LoadingComponent,
    GenericSnackbar,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    drawer: null,
    currentPass: '',
  }),
  methods: {
    logout() {
      this.$store.commit('logout');
      this.$router.push({ name: 'candidate:offers' });
    },
  },
};
</script>

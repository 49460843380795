<template>
  <BaseComponent>
    <template slot="drawer">
      <v-list
        class="pt-0"
        dense
      >
        <v-list-tile :to="{name: 'admin:home'}">
          <v-list-tile-action>
            <v-icon>fas fa-home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Inicio</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile :to="{name: 'admin:offers'}">
          <v-list-tile-action>
            <v-icon>work</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Ofertas</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile :to="{name: 'admin:candidates'}">
          <v-list-tile-action>
            <v-icon>people</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Base de Candidatos</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile :to="{name: 'admin:config'}">
          <v-list-tile-action>
            <v-icon>build</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Configuración</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile :to="{name: 'admin:archive'}">
          <v-list-tile-action>
            <v-icon>mdi-folder-download</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Archivo de Longlists</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click="feedback = true">
          <v-list-tile-action>
            <v-icon>mdi-file-document</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Enviar Feedback</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-dialog
          v-model="feedback"
          max-width="500"
          persistent
        >
          <v-card>
            <v-card-title>
              Enviar Feedback
            </v-card-title>
            <v-card-text>
              <h4>Envía comentarios, sugerencias y problemas con el sistema acá:</h4>
              <v-text-field
                v-model="title"
                label="Título del mensaje"
                outline
              />
              <v-textarea
                v-model="comment"
                label="Escribe tu mensaje aquí..."
                outline
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="success"
                @click="sendFeedback"
              >
                Enviar
              </v-btn>
              <v-btn
                color="warning"
                @click="feedback = false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list>
    </template>
  </BaseComponent>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import BaseComponent from '../Base.vue';

export default {
  components: {
    BaseComponent,
  },
  mixins: [JWTConfigMixin],
  props: {
    source: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    drawer: null,
    feedback: false,
    comment: '',
    title: '',
  }),
  methods: {
    sendFeedback() {
      this.$store.commit('setLoading');
      const payload = {
        title: this.title,
        comment: this.comment,
      };
      const ep = this.$store.state.endpoints.offers.admin.sendFeedback;
      axios.post(ep, payload, this.jwtConfig)
        .then(() => {
          this.feedback = false;
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Comentario enviado con éxito.');
        });
    },
    logout() {
      this.$store.commit('logout');
      this.$router.push({ name: 'candidate:offers' });
    },
  },
};
</script>
